@import '../../scss/mixin';
@import '../../scss/variables';

.InputFieldNormal {
    width: 100%;
    padding: 1em 1em;
    border-radius: 5px;
    background-color: rgb(207, 207, 207);
    font-family: $main-font;
    font-size: 15px;
    color: black;
    outline: none;
    box-shadow: none;
    border: 0;
    border-bottom: 2px solid transparent;
    transition: all 0.2s ease-out;

    &.Input--error {
        border-bottom: 2px solid orange;
    }

    &__label {
        margin-top: 0.4em;
        font-size: 13px;
        color: orange;
    }

    &:disabled {
        background-color: #fff;
        opacity: 0.7;
        cursor: not-allowed;
    }
}