.container {
    display: flex;
    .row & {flex-direction: row; }
    .column & {flex-direction: column; height: 200px;}
    &.flex-start {
      justify-content: flex-start;
    }
    &.flex-end {
      justify-content: flex-end;
    }
    &.center {
      justify-content: center;
    }
    &.space-around {
      justify-content: space-around;
    }
    &.space-between {
      justify-content: space-between;
    }
  }

  .btn-primary {
    color: #fff;
    background-color: #2d5989;
    border-color: #6b94c1;
}

