.nav {
    a.nav_link:hover {
    cursor: pointer;
    //color: red;
    }
  }

.form-control {
  border: 1px solid #2c2e30;
  color: #a6d0fb;
}

.InputField:focus {
  background-color: #2c2e30;
}

.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #c6c8cb;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #d7d9db;
  background-color: #232e3c;
  border-color: #2c2d2e #1c1c1c #fff;
}