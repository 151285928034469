@import '../../scss/mixin';
@import '../../scss/variables';

.Searchbar {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 1.2rem;
    cursor: pointer;

    @include mq(sm) {
      margin-right: 2rem;
    }

    &--search {
      background-color: rgba($color: $black, $alpha: 0.25);
      border: 1px solid #777777;
      padding: 7px 30px 7px 7px;
      font-size: 12px;
      color: $white;
      outline: none;
      border-radius: 6px;
      width: 0;
      opacity: 0;
      transition: all 0.2s ease-out;

      @include mq(sm) {
        font-size: 14px;
      }

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &::placeholder {
        color: #777777;
        opacity: 1;
      }
    }

    &--toggler {
      margin-left: 0.5rem;

      svg {
        width: 1.3rem;
        height: 1.3rem;

        @include mq(sm) {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    &--clear {
      position: absolute;
      right: 18%;
      top: 55%;
      transform: translateY(-50%);
      cursor: pointer;
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease-in-out;

      &.typing {
        opacity: 1;
        pointer-events: all;
        transition-delay: 0.1s;
      }
    }

    &--active {
      width: 160px;
      opacity: 1;

      @include mq(sm) {
        width: 200px;
      }
    }
}