.Course {
    margin-top: 80px;
    // background-color: #252525;
    // position: relative;
    // width: 100%;
    // display: block;
    // align-items: flex-end;
    // height: 100px;
}

.card-body {
   // background-color: #252525;
    background-color: #fff;
}

.card-info {
    box-shadow: 0px 2px 9px 0px #afb1b5;
}