/* body {
  font-family: Roboto;
  text-align: center;
} */

.certificate-container {
  padding: 10px;
  width: 1024px;
}
.certificate {
  border: 20px solid #0c5280;
  padding: 25px;
  height: 600px;
  position: relative;
}

.certificate:after {
  content: "";
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  position: absolute;
  /* background-image: url(https://image.ibb.co/ckrVv7/water_mark_logo.png); */
  background-size: 100%;
  z-index: -1;
  text-align: center;
}
.certificate-header {
  text-align: center;
}

/* .certificate-header > .logo {
  width: 80px;
  height: 80px;
} */

.certificate-header > .logo {
  width: 300px;
  height: 140px;
}

.certificate-header > .certqr {
  margin-top: 0px;
  margin-left: 30px;
  width: 120px;
  height: 120px;
}

.certificate-title {
  text-align: center;
}

.certificate-body {
  text-align: center;
}

h1 {
  font-weight: 400;
  font-size: 48px;
  color: #0c5280;
}

.student-name {
  font-size: 24px;
}

.certificate-content {
  margin: 0 auto;
  width: 750px;
}

.about-certificate {
  width: 380px;
  margin: 0 auto;
}

.topic-description {
  text-align: center;
}
