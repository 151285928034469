.Course {
    margin-top: 80px;
    // background-color: #252525;
    // position: relative;
    // width: 100%;
    // display: block;
    // align-items: flex-end;
    // height: 100px;
}

.card-body {
   // background-color: #252525;
}