@import '../../scss/mixin';
@import '../../scss/variables';

.Banner {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    height: 90vh;
    background: $black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    @include mq(lg) {
        height: 80vh;
        align-items: center;
    }

    &__loadsection {
        .loading,
        .errored {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80vh;
            background: $black;
            font-size: 20px;
            line-height: 1.6;
            font-weight: 600;
            text-shadow: 2px 2px 4px rgba($color: $black, $alpha: 0.45);
        }
    }

    &__content {
        color: #000;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 4vw;
        padding-bottom: 10vh;
        z-index: 1;

        @include mq(lg) {
            align-items: flex-start;
            padding-bottom: unset;
            text-align: left;
        }

        &--title {
            font-size: 30px;
            line-height: 1;
            //text-shadow: 1px 1px 1px rgba($color: #FFF, $alpha: 1);
            text-shadow: 4px 2px 7px #fff;

            @include mq('xs'){
                font-size: 35px;
            }
            @include mq('sm'){
                font-size: 40px;
            }
            @include mq('md'){
                font-size: 60px;
            }
            @include mq('lg'){
                max-width: 50%;
            }
        }

        &--description {
            font-size: 14px;
            line-height: 1.6;
            //text-shadow: 1px 1px 1px rgba($color: #FFF, $alpha: 1);
            text-shadow: 1px 1px 11px #fff;
            margin-top: 1rem;
            color: #fff;

            @include mq('sm'){
                font-size: 16px;
            }
            @include mq('md'){
                font-size: 2vw;
                max-width: 60vw;
            }
            @include mq('lg'){
                font-size: 1.2vw;
                max-width: 30vw;
                margin-top: 1vw;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        margin-top: 1vw;
    }

    &__button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;
        background-color: #232e3c;
        color: #f2f2f2;
        padding: 10px 16px;
        margin-left: 10px;
        border-radius: 6px;
        border: 0;
        margin-top: 1em;
        font-family: $main-font;
        font-size: 13px;
        font-weight: 700;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;

        @include mq('xs'){
            min-width: 150px;
            font-size: 15px;
            font-weight: bold;
            padding: 12px 20px;
        }

        @include mq('md'){
          //  font-size: 15px;
          font-size: 18px;
          font-weight: bold;
        }

        &:hover {
            background-color: rgba(#46515f, 1);
        }

        &:first-child {
            margin-left: 0;
            background-color: $red;
            //color: $white;

            // &:hover {
            //     background-color: darken($red, 5);
            // }
            &:hover {
                background-color: rgba(#cf3000, 1);
            }
        }

        span {
            margin-left: 8px;
        }
    }

    &__panel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-color: rgba($color: $black, $alpha: 0.2);
    }

    &__bottom-shadow {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 7.5rem;
        //background: linear-gradient(180deg, transparent, rgba(14, 14, 14, 0.6), $black);
        //background: rgb(237,242,249);
        //background: linear-gradient(180deg, rgba(237,242,249,0) 0%, rgba(237,242,249,0.2945553221288515) 35%, rgba(237,242,249,1) 100%);
    }
}