@import '../../scss/mixin';
@import '../../scss/variables';

.SignIn {
  &__form {
    &--inputwrp {
      margin: 0.6em 0;

      @include mq(xs) {
        margin: 1em 0;
      }
    }

    &--button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 45px;
      box-shadow: none;
      border: 1px solid transparent;
      padding: 1.2em 0.8em;
      margin-top: 0.5em;
      border-radius: 5px;
      font-family: $main-font;
      font-size: 13px;
      font-weight: 500;
      line-height: 1;
      transition: all 0.2s ease-out;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:nth-of-type(1) {
        margin-top: 1.5em;

        @include mq(xs) {
          margin-top: 2em;
        }
      }

      @include mq(xs) {
        min-height: 60px;
        font-size: 15px;
      }

      svg {
        font-size: 1.5em;
        margin-right: 0.4em;
      }

      &.button {
        &__submit {
          background-color: $red;
          color: $white;

          &:hover {
            background-color: darken($color: $red, $amount: 8);
            transform: scale(0.95);
          }
        }

        &__google {
          background-color: $blue-google;
          color: $white;
          border: 1px solid $black;

          &:hover {
            background-color: $black;
            color: $white;
            border: 1px solid $white;
            transform: scale(0.95);
          }
        }

        &__anonymous {
          background-color: transparent;
          color: darken($white, 10%);
          border: 1px solid darken($white, 10%);

          &:hover {
            color: $red;
            border: 1px solid $red;
            transform: scale(0.95);
          }
        }
      }

      &.loading {
        background-color: #333;
        border: 1px solid $white;
        cursor: progress;
        &:hover {
          background-color: #333;
          transform: none;
        }
      }

    }
  }
}